import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Test Week`}</em></p>
    <p>{`OHS 1-1-1-1 to a 1RM`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Pre Open WOD 6`}</em></strong></p>
    <p><strong parentName="p">{`RX Division`}</strong></p>
    <p>{`8:00 AMRAP of:`}</p>
    <p>{`30-Squat Snatches (95/65)`}</p>
    <p>{`15-Ring Muscle Ups`}</p>
    <p>{`20-Squat Snatches (115/75)`}</p>
    <p>{`10-Ring Muscle Ups`}</p>
    <p>{`10-Squat Snatches (135/95)`}</p>
    <p>{`5-Ring Muscle Ups`}</p>
    <p><em parentName="p">{`*`}{`Must load your own bar.  Power snatch to an OHS is acceptable.`}</em></p>
    <p><em parentName="p">{`*`}{`If you cannot ring muscle up score as far as you get with the initial
30 snatches at RX weight, then scale as needed to proceed for 8:00 to
finish the wod.  `}</em></p>
    <p><strong parentName="p">{`Scaled/Masters Division`}</strong></p>
    <p>{`8:00 AMRAP of:`}</p>
    <p>{`30-Power Snatches (75/55)`}</p>
    <p>{`15-Jumping Ring Pullups (Rings set 12″ above head)`}</p>
    <p>{`20-Power Snatches (95/65)`}</p>
    <p>{`10-Jumping Ring Pullups`}</p>
    <p>{`10-Power Snatches (115/75)`}</p>
    <p>{`5-Jumping Ring Pullups`}</p>
    <p><em parentName="p">{`*`}{`If you finish start over at 30 snatches but with 115/75`}{`#`}{`.`}</em></p>
    <p><em parentName="p">{`*`}{`Full elbow extension on the jumping ring pull-ups, and chin must get
above the bottom of the rings.`}</em></p>
    <p><strong parentName="p">{`*`}{`CrossFit Kids today at 9:30am at Louisville East.`}</strong></p>
    <p><strong parentName="p">{`*`}{`Saturday, February 4th, we will have our 3rd Annual Sports Nutrition
seminar with Dawn from Sports Nutrition 2 Go in Cincinnati. The seminar
will be from 12-2pm. \\$35/person for the seminar. We will need 20 people
in order to have this so please email Daniel\\@crossfitlouisvilleeast.com
if you’d like to attend.`}</strong></p>
    <p><strong parentName="p">{`Subjects covered will include:`}</strong></p>
    <p><strong parentName="p">{`Hydration—How impacts performance, electrolytes, what to drink`}</strong></p>
    <p><strong parentName="p">{`Breakfast—effects afternoon performance by 5% and effects appetite`}</strong></p>
    <p><strong parentName="p">{`Nutrient Timing—what to eat before/after, when`}</strong></p>
    <p><strong parentName="p">{`Sleep and Nutrition on performance---Discuss percentages on outcomes`}</strong></p>
    <p><strong parentName="p">{`Discuss different nutrition philosophies`}</strong></p>
    <p><strong parentName="p">{`Supplements and Labs: what to take and which labs to draw`}</strong></p>
    <p><strong parentName="p">{`Also: She will cover what ever the audience would like! If you would
like to attend and you want to forward questions ahead of time please
send them to: Daniel\\@crossfitlouisvilleeast.com.`}</strong></p>
    <p><strong parentName="p">{`*`}{`Want to learn the exact number of calories your body burns each day?
Metabolic Testing will be offered before and after the seminar as well!
\\$65 for Metabolic Testing.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      